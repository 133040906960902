import React, { useState, useRef } from "react";
import { Button } from "@syscocorporation/cx-foundation-react";
import ModalComponent from "../../components/Modal";
import InputField from "../../components/InputField";
import SignaturePad from "react-signature-canvas";
import data from "../../../data.json";
import "./style.css";

const SignatureVerification = ({ setFormName, setFormTitle, setSign, closePopup }) => {
  const [name, setName] = useState(setFormName);
  const [title, setTitle] = useState(setFormTitle);
  const [errorMessage, setErrorMessage]=useState('');
  const signPadRef = useRef();

  const clearDrawnSignature = () => {
    signPadRef.current.clear();
  };

  const applySignature = () => {
    if (signPadRef.current.isEmpty()) {
        setErrorMessage("Please provide a signature.");
    } else {
        const dataURL = signPadRef.current.getTrimmedCanvas().toDataURL("image/png");
        setSign(dataURL);
        setErrorMessage('');
        closePopup(false);
    }
  };

  const handleCanvasChange = () => {
    if (!signPadRef.current.isEmpty()) {
      setErrorMessage('');
    }
  };

  return (
    <div className="signatureModal">
      <ModalComponent title="E-Signature">
        <div className="input-fields">
          <div>
            <InputField
                label={data.nameHeading}
                className="signerName"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            ></InputField>
            <InputField
                label={data.signatureNameTitle}
                className="signerTitle"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            ></InputField>
          </div>
        </div>
        <div className="signMessage">{data.signMessage}</div> 
        <div className="draw-section">
          <SignaturePad
            ref={signPadRef}
            canvasProps={{ className: "signature-canvas" }}
            onEnd={handleCanvasChange} 
          />
          <div className="sign-error-wrapper">
            {errorMessage && <div className="signErrorMessage">{errorMessage}</div>}
            <div className="clear-signature-actions" onClick={clearDrawnSignature}>
              {data.ClearSignatureBtn}
            </div>
          </div>
        </div>
        <div className="signature-button-wrapper">
          <Button type="primary" onClick={applySignature} disabled={!!errorMessage}>
            {data.ApplySignatureBtn}
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SignatureVerification;
