export const stateTaxIdMappingForMTC = {
  AK: "State Registration Sellers Permit or ID Number of PurchaserAKARSSTC 1",
  MO: "State Registration Sellers Permit or ID Number of PurchaserMO 19",
  AL: "State Registration Sellers Permit or ID Number of PurchaserAL 2",
  NE: "State Registration Sellers Permit or ID Number of PurchaserNE",
  AR: "State Registration Sellers Permit or ID Number of PurchaserAR",
  NV: "State Registration Sellers Permit or ID Number of PurchaserNV 20",
  AZ: "State Registration Sellers Permit or ID Number of PurchaserAZ 3",
  NJ: "State Registration Sellers Permit or ID Number of PurchaserNJ",
  CA: "State Registration Sellers Permit or ID Number of PurchaserCA 4",
  NM: "State Registration Sellers Permit or ID Number of PurchaserNM 521",
  CO: "State Registration Sellers Permit or ID Number of PurchaserCO 56",
  NC: "State Registration Sellers Permit or ID Number of PurchaserNC 22",
  CT: "State Registration Sellers Permit or ID Number of PurchaserCT 7",
  ND: "State Registration Sellers Permit or ID Number of PurchaserND",
  FL: "State Registration Sellers Permit or ID Number of PurchaserFL8",
  OH: "State Registration Sellers Permit or ID Number of PurchaserOH23",
  GA: "State Registration Sellers Permit or ID Number of PurchaserGA9",
  OK: "State Registration Sellers Permit or ID Number of PurchaserOK 24",
  HI: "State Registration Sellers Permit or ID Number of PurchaserHI 510",
  PA: "State Registration Sellers Permit or ID Number of PurchaserPA 25",
  ID: "State Registration Sellers Permit or ID Number of PurchaserID11",
  RI: "State Registration Sellers Permit or ID Number of PurchaserRI 26",
  IL: "State Registration Sellers Permit or ID Number of PurchaserIL 512",
  SC: "State Registration Sellers Permit or ID Number of PurchaserSC",
  IA: "State Registration Sellers Permit or ID Number of PurchaserIA",
  SD: "State Registration Sellers Permit or ID Number of PurchaserSD 27",
  KS: "State Registration Sellers Permit or ID Number of PurchaserKS13",
  TN: "State Registration Sellers Permit or ID Number of PurchaserTN 28",
  KY: "State Registration Sellers Permit or ID Number of PurchaserKY14",
  TX: "State Registration Sellers Permit or ID Number of PurchaserTX 29",
  ME: "State Registration Sellers Permit or ID Number of PurchaserME 15",
  UT: "State Registration Sellers Permit or ID Number of PurchaserUT",
  MD: "State Registration Sellers Permit or ID Number of PurchaserMD 16",
  VT: "State Registration Sellers Permit or ID Number of PurchaserVT 30",
  MI: "State Registration Sellers Permit or ID Number of PurchaserMI 17",
  WA: "State Registration Sellers Permit or ID Number of PurchaserWA 31",
  MN: "State Registration Sellers Permit or ID Number of PurchaserMN 18",
  WI: "State Registration Sellers Permit or ID Number of PurchaserWI 32",
};

export const stateMappingForSSUTA = {
  AR: {
    "Item.ARIdentificationNumber": "AR Identification Number",
    "Item.AR ID Source": "AR ID Source",
    "Item.ARexemption": "AR Exemption",
  },
  GA: {
    "Item.GAIdentificationNumber": "GA Identification Number",
    "Item.GA ID Source": "GA ID Source",
    "Item.GAexemption": "GA Exemption",
  },
  IA: {
    "Item.IAIdentificationNumber": "IA Identification Number",
    "Item.IA ID Source": "IA ID Source",
    "Item.IAexemption": "IA Exemption",
  },
  IN: {
    "Item.INIdentificationNumber": "IN Identification Number",
    "Item.IN ID Source": "IN ID Source",
    "Item.INexemption": "IN Exemption",
  },
  KS: {
    "Item.KSIdentificationNumber": "KS Identification Number",
    "Item.KS ID Source": "KS ID Source",
    "Item.KSexemption": "KS Exemption",
  },
  KY: {
    "Item.KYIdentificationNumber": "KY Identification Number",
    "Item.KY ID Source": "KY ID Source",
    "Item.KYexemption": "KY Exemption",
  },
  MI: {
    "Item.MIIdentificationNumber": "MI Identification Number",
    "Item.MI ID Source": "MI ID Source",
    "Item.MIexemption": "MI Exemption",
  },
  MN: {
    "Item.MNIdentificationNumber": "MN Identification Number",
    "Item.MN ID Source": "MN ID Source",
    "Item.MNexemption": "MN Exemption",
  },
  NC: {
    "Item.NCIdentificationNumber": "NC Identification Number",
    "Item.NC ID Source": "NC ID Source",
    "Item.NCexemption": "NC Exemption",
  },
  ND: {
    "Item.NDIdentificationNumber": "ND Identification Number",
    "Item.ND ID Source": "ND ID Source",
    "Item.NDexemption": "ND Exemption",
  },
  NE: {
    "Item.NEIdentificationNumber": "NE Identification Number",
    "Item.NE ID Source": "NE ID Source",
    "Item.NEexemption": "NE Exemption",
  },
  NJ: {
    "Item.NJIdentificationNumber": "NJ Identification Number",
    "Item.NJ ID Source": "NJ ID Source",
    "Item.NJexemption": "NJ Exemption",
  },
  NV: {
    "Item.NVIdentificationNumber": "NV Identification Number",
    "Item.NV ID Source": "NV ID Source",
    "Item.NVexemption": "NV Exemption",
  },
  OH: {
    "Item.OHIdentificationNumber": "OH Identification Number",
    "Item.OH ID Source": "OH ID Source",
    "Item.OHexemption": "OH Exemption",
  },
  OK: {
    "Item.OKIdentificationNumber": "OK Identification Number",
    "Item.OK ID Source": "OK ID Source",
    "Item.OKexemption": "OK Exemption",
  },
  RI: {
    "Item.RIIdentificationNumber": "RI Identification Number",
    "Item.RI ID Source": "RI ID Source",
    "Item.RIexemption": "RI Exemption",
  },
  SD: {
    "Item.SDIdentificationNumber": "SD Identification Number",
    "Item.SD ID Source": "SD ID Source",
    "Item.SDexemption": "SD Exemption",
  },
  TN: {
    "Item.TNIdentificationNumber": "TN Identification Number",
    "Item.TN ID Source": "TN ID Source",
    "Item.TNexemption": "TN Exemption",
  },
  UT: {
    "Item.UTIdentificationNumber": "UT Identification Number",
    "Item.UT ID Source": "UT ID Source",
    "Item.UTexemption": "UT Exemption",
  },
  VT: {
    "Item.VTIdentificationNumber": "VT Identification Number",
    "Item.VT ID Source": "VT ID Source",
    "Item.VTexemption": "VT Exemption",
  },
  WA: {
    "Item.WAIdentificationNumber": "WA Identification Number",
    "Item.WA ID Source": "WA ID Source",
    "Item.WAexemption": "WA Exemption",
  },
  WI: {
    "Item.WIIdentificationNumber": "WI Identification Number",
    "Item.WI ID Source": "WI ID Source",
    "Item.WIexemption": "WI Exemption",
  },
  WV: {
    "Item.WVIdentificationNumber": "WV Identification Number",
    "Item.WV ID Source": "WV ID Source",
    "Item.WVexemption": "WV Exemption",
  },
  WY: {
    "Item.WYIdentificationNumber": "WY Identification Number",
    "Item.WY ID Source": "WY ID Source",
    "Item.WYexemption": "WY Exemption",
  },
};
