import React, { useEffect, useState } from "react";
import { signatureController } from "../../../utils/HomeApis";
import { stateMappingForSSUTA, stateTaxIdMappingForMTC } from "./mapping";

const DataGenerator = ({
  selectedId,
  dataToReturn,
  formData,
  CORAAPIResponse,
}) => {
  const [signID, setSignID] = useState(null);
  useEffect(() => {
    const fetchSignatureId = async () => {
      try {
        const { signature_id } = await signatureController(CORAAPIResponse);
        setSignID(signature_id);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchSignatureId();
  }, [CORAAPIResponse]);

  const safeValue = (value) =>
    value === undefined || value === null ? "" : value;

  const generateDataForFormssutaa = (formData, CORAAPIResponse) => {
    const addressParts = CORAAPIResponse.address.split(",");
    const street = addressParts[0].trim();
    const ship_to_city = addressParts[1].trim();
    const ship_to_state = addressParts[2].trim();
    const ship_to_country = addressParts[3].trim();
    const ship_to_zip = addressParts[4].trim();

    const stateData =
      stateMappingForSSUTA[
        CORAAPIResponse.shipping_address?.[0]?.ship_to_state
      ] || {};

    const Style_of_business = safeValue(
      CORAAPIResponse.style_of_business.toLowerCase()
    );
    let retailer_10_ssuta = false;
    let wholesaler_13_ssuta = false;
    let other_20_ssuta = false;
    let resale_g_susta = false;
    let other_m_susta = false;
    let g_other;

    if (Style_of_business.includes("restaurant")) {
      retailer_10_ssuta = true;
      resale_g_susta = true;
      g_other = "G";
    } else if (Style_of_business === "wholesaler") {
      wholesaler_13_ssuta = true;
      resale_g_susta = true;
      g_other = "G";
    } else if (
      !(
        Style_of_business.includes("restaurant") ||
        Style_of_business === "wholesaler"
      )
    ) {
      other_m_susta = true;
      other_20_ssuta = true;
      g_other = "L";
    }
    return {
      sign_stamp_id: signID,
      "Item.Multistate": safeValue(""),
      "Item.NR.Invoice/PurchaseOrderNumber": safeValue(""),
      "Item.NR.OtherExplain": safeValue(formData.reason),
      "Item.NR.FederalGovernment": safeValue(""),
      "Item.NR.StateLocalGovernment": safeValue(""),
      "Item.NR.TribalGovernment": safeValue(""),
      "Item.NR.ForeignDiplomat": safeValue(""),
      "Item.NR.OtherExplination": safeValue(formData.reason),
      "Item.Your.Name": safeValue(formData.legalName),
      "Item.BusinessAddress": safeValue(formData.streetAdress),
      "Item.City": safeValue(formData.city),
      "Item.PurchaserStateCountry": safeValue(formData.state),
      "Item.Country": safeValue(CORAAPIResponse.seller_country),
      "Item.BusinessPostalcode": safeValue(formData.zip),
      "Item.NameofSeller": safeValue(CORAAPIResponse.legal_enitity_name),
      "Item.SellersAddress": safeValue(street),
      "Item.SellersCity": safeValue(ship_to_city),
      "Item.SellersStateCountry": safeValue(ship_to_state),
      "Item.SellerCountry": safeValue(ship_to_country),
      "Item.SellersPostalCode": safeValue(ship_to_zip),
      "Item.Checkbox": safeValue("Yes"),
      "Item.Checkbox2": safeValue("Yes"),
      ...(stateData && {
        [Object.keys(stateData)[0]]: safeValue(formData.stateTaxId),
        [Object.keys(stateData)[1]]: safeValue(
          CORAAPIResponse.shipping_address?.[0]?.ship_to_state
        ),
        [Object.keys(stateData)[2]]: safeValue(g_other),
      }),
      "Item.PrintName": safeValue(formData.name),
      "Item.Title": safeValue(formData.title),
      "Item.Date": safeValue(formData.date),
      Signature: safeValue(formData.name),
      retailer_10_ssuta: safeValue(retailer_10_ssuta),
      wholesaler_13_ssuta: safeValue(wholesaler_13_ssuta),
      other_20_ssuta: safeValue(other_20_ssuta),
      resale_g_susta: safeValue(resale_g_susta),
      other_m_susta: safeValue(other_m_susta),
    };
  };

  const generateDataForFormTexas = (formData, CORAAPIResponse) => {
    const addressParts = CORAAPIResponse.address.split(",");
    const street = addressParts[0].trim();
    const city = addressParts[1].trim();
    const state = addressParts[2].trim();
    const zip = addressParts[4].trim();
    const safeValue = (value) =>
      value === undefined || value === null ? "" : value;

    return {
      sign_stamp_id_CU4W: safeValue(signID),
      Purchaser: safeValue(formData?.legalName),
      "Purchaser phone": safeValue(formData?.phonenumber),
      "Purchaser address": safeValue(formData?.streetAdress),
      "Purchaser city, state, ZIP": safeValue(
        formData?.city && formData?.state && formData?.zip
          ? `${formData.city}, ${formData.state}, ${formData.zip}`
          : ""
      ),
      "Permit number": safeValue(formData?.stateTaxId),
      RFCnumber: safeValue(formData?.RFCnumber),
      Seller: safeValue(CORAAPIResponse?.legal_enitity_name),
      "Seller street": safeValue(street),
      "Seller city, state, ZIP":
        safeValue(city) + ", " + safeValue(state) + ", " + safeValue(zip),
      "Business activity": safeValue(formData?.description),
      "Purchaser title": safeValue(formData?.title),
      "Purchaser sig date": safeValue(formData.date),
      "Exemption purchaser": safeValue(formData?.legalName),
      "Exemption purchaser street": safeValue(formData?.streetAdress),
      "Exemption purchaser phone": safeValue(formData?.phonenumber),
      "Exemption purchaser city": safeValue(
        formData?.city && formData?.state && formData?.zip
          ? `${formData.city}, ${formData.state}, ${formData.zip}`
          : ""
      ),
      "Exempt seller": safeValue(CORAAPIResponse?.legal_enitity_name),
      "Exempt seller street": safeValue(street),
      "Exempt seller city":
        safeValue(city) + ", " + safeValue(state) + ", " + safeValue(zip),
      "Exempt item description":
        "Restaurant related manufacturing equipment, tools, chemicals, and cleaning supplies.",
      "Exemption reason": "34 TAC SEC 3.3000 & SEC 151.318 - MANUFACTURING",
      "Exempt purchaser title": safeValue(formData?.title),
      "Exempt purchaser sig date": safeValue(formData.date),
      "Items description": safeValue(formData.discriptionofitems),
      "Signature Purchaser": safeValue(formData?.name),
      "Signature Purchaser2": safeValue(formData?.name),
    };
  };

  const generateDataForFormSt12 = (formData, CORAAPIResponse) => {
    const safeValue = (value) =>
      value === undefined || value === null ? "" : value;
    const addressParts = CORAAPIResponse.address.split(",");
    const addressObject = {
      seller_address: addressParts[0].trim(),
      seller_city: addressParts[1].trim(),
      seller_state: addressParts[2].trim(),
      seller_zip: addressParts[4].trim(),
    };
    return {
      sign_stamp_id: safeValue(signID),
      "Seller’s name": safeValue(CORAAPIResponse.legal_enitity_name),
      "Seller Address": safeValue(addressObject.seller_address),
      "Sellers City": safeValue(addressObject.seller_city),
      "Sellers State": safeValue(addressObject.seller_state),
      "Sellers Zip": safeValue(addressObject.seller_zip),
      "Description of property": safeValue(formData.discriptionofitems),
      Signature: safeValue(formData.name),
      Title: safeValue(formData.title),
      "Name of Company": safeValue(formData.legalName),
      "Federal Id number": safeValue(formData.stateTaxId),
      Address: safeValue(formData.streetAdress),
      State: safeValue(formData.state),
      Zip: safeValue(formData.zip),
      City: safeValue(formData.city),
      Date: safeValue(formData.date),
      "Blankate Certificate": true,
    };
  };

  const generateDataForFormMultipleStates = (formData, CORAAPIResponse) => {
    const safeValue = (value) =>
      value === undefined || value === null ? "" : value;
    const styleOfBusiness = CORAAPIResponse.style_of_business.toLowerCase();
    const businessTypes = {
      Retailer: styleOfBusiness.includes("restaurant"),
      Wholesaler: styleOfBusiness === "wholesaler",
      mtc_other: !(
        styleOfBusiness.includes("restaurant") ||
        styleOfBusiness === "wholesaler"
      ),
    };
    const shipToState = safeValue(
      CORAAPIResponse.shipping_address?.[0]?.ship_to_state
    );
    const stateTaxId = stateTaxIdMappingForMTC[shipToState] || "";
    const combinedValue =
      shipToState === "CO" && formData.localTaxId
        ? `${safeValue(formData.stateTaxId)},${safeValue(
            formData.localTaxId
          )}`.trim()
        : safeValue(formData.stateTaxId);

    const description = safeValue(formData.discriptionofitems);
    const seller1Description =
      description.length > 45 ? description.slice(0, 45) : description;
    const seller2Description =
      description.length > 45 ? description.slice(45) : "";
    return {
      sign_stamp_id: safeValue(signID),
      "Issued to Seller": safeValue(CORAAPIResponse.legal_enitity_name),
      Address: safeValue(CORAAPIResponse.address),
      "Name of Firm Buyer": safeValue(formData.legalName),
      Address_2:
        safeValue(formData.streetAdress) +
        ", " +
        safeValue(formData.city) +
        ", " +
        safeValue(formData.state) +
        ", " +
        safeValue(formData.zip),
      "Description of Business": safeValue(formData.description),
      "General description of tangible property or taxable services to be purchased from the Seller 1":
        safeValue(seller1Description),
      "General description of tangible property or taxable services to be purchased from the Seller 2":
        safeValue(seller2Description),
      [stateTaxId]: safeValue(combinedValue),
      Signature: safeValue(formData.name),
      Title: safeValue(formData.title),
      Date: safeValue(formData.date),
      ...businessTypes,
      mtc_other_reason: safeValue(formData.reason),
    };
  };
  const generateDataForFormSt10 = (formData, CORAAPIResponse) => {
    const safeValue = (value) =>
      value === undefined || value === null ? "" : value;
    const addressParts = safeValue(CORAAPIResponse.address).split(",");
    const addressObject = {
      seller_address: safeValue(addressParts[0]).trim(),
      seller_city: safeValue(addressParts[1]).trim(),
      seller_state: safeValue(addressParts[2]).trim(),
      seller_zip: safeValue(addressParts[4]).trim(),
    };
    return {
      sign_stamp_id: safeValue(signID),
      "Name of supplier": safeValue(CORAAPIResponse.legal_enitity_name),
      "Month, Day and Year": safeValue(formData.date),
      "Number and street or rural route": safeValue(
        addressObject.seller_address
      ),
      "Check Box1": true,
      "Name of Dealer": safeValue(formData.legalName),
      "Virginia Account Number": safeValue(formData.stateTaxId),
      "Business Type": safeValue(formData.description),
      Title: safeValue(formData.title),
      "Trading As Address": safeValue(formData.streetAdress),
      City: safeValue(addressObject.seller_city),
      State: safeValue(addressObject.seller_state),
      zipcode: safeValue(addressObject.seller_zip),
      "City of dealer": safeValue(formData.city),
      "State of dealer": safeValue(formData.state),
      "zip code of dealer": safeValue(formData.zip),
      Signature: safeValue(formData.name),
    };
  };
  const generateDataForFormSt120 = (formData, CORAAPIResponse) => {
    const safeValue = (value) =>
      value === undefined || value === null ? "" : value;

    const addressParts = safeValue(CORAAPIResponse.address).split(",");
    const addressObject = {
      seller_address: safeValue(addressParts[0]).trim(),
      seller_city: safeValue(addressParts[1]).trim(),
      seller_state: safeValue(addressParts[2]).trim(),
      seller_zip: safeValue(addressParts[4]).trim(),
    };
    const Style_of_business = safeValue(
      CORAAPIResponse.style_of_business.toLowerCase()
    );
    return {
      sign_stamp_id: safeValue(signID),
      "single-use certificate": "Yes",
      "i am engaged in the business of": safeValue(formData.description),
      "and principally sell": safeValue(formData.discriptionofitems),
      "coa number 1": safeValue(formData.stateTaxId),
      "coa number 2": "",
      "and expires on": "",
      "state/jurisdiction": "",
      "registration number": "",
      "I certify that I am": "",
      "purchaser's name": safeValue(formData.legalName),
      "purchaser's address": safeValue(formData.streetAdress),
      "purchaser city": safeValue(formData.city),
      "purchaser state": safeValue(formData.state),
      "purchaser zip code": safeValue(formData.zip),
      "sellers name": safeValue(CORAAPIResponse.legal_enitity_name),
      "seller's address": safeValue(addressObject.seller_address),
      "seller city": safeValue(addressObject.seller_city),
      "seller state": safeValue(addressObject.seller_state),
      "seller zip code": safeValue(addressObject.seller_zip),
      "print signer's name": safeValue(formData.name + ", " + formData.title),
      "date signed": safeValue(formData.date),
      "Blankate Certificate": true,
      New_york_chekced: true,
      tangable_property_st120_checked: true,
      type_food_st120_checked:
        Style_of_business === "restaurant" ||
        Style_of_business === "caterer" ||
        Style_of_business === "Hospital w/Cafeteria" ||
        Style_of_business === "Hotel w/Restaurant",
      st120_tangible_property_NY: false,
      st120_tangible_outsideNY: false,
      Signature: safeValue(formData.name),
    };
  };
  const generateDataForFormSt4 = (formData, CORAAPIResponse) => {
    const safeValue = (value) =>
      value === undefined || value === null ? "" : value;

    const addressParts = safeValue(CORAAPIResponse.address).split(",");
    const addressObject = {
      vendor_address: safeValue(addressParts[0]).trim(),
      vendor_city: safeValue(addressParts[1]).trim(),
      vendor_state: safeValue(addressParts[2]).trim(),
      vendor_zip: safeValue(addressParts[4]).trim(),
    };
    return {
      sign_stamp_id: safeValue(signID),
      purchaser_name: safeValue(formData.legalName),
      purchaser_address: safeValue(formData.streetAdress),
      purchaser_state: safeValue(formData.state),
      purchaser_zip: safeValue(formData.zip),
      purchaser_city: safeValue(formData.city),
      purchaser_typeof_business: safeValue(formData.description),
      tangible_personal_property: safeValue(formData.discriptionofitems),
      tangible_vendor_name: safeValue(CORAAPIResponse.legal_enitity_name),
      vendor_address: safeValue(addressObject.vendor_address),
      vendor_city: safeValue(addressObject.vendor_city),
      vendor_state: safeValue(addressObject.vendor_state),
      vendor_zip: safeValue(addressObject.vendor_zip),
      purchaser_sign: safeValue(formData.name),
      purchaser_title: safeValue(formData.title),
      current_date: safeValue(formData.date),
      single_purchaser_certificate: false,
      blanket_certificate: false,
      ID_number: safeValue(formData.stateTaxId),
    };
  };

  useEffect(() => {
    if (signID) {
      let dataGenerated;

      switch (selectedId?.toLowerCase()) {
        case "ssuta":
          dataGenerated = generateDataForFormssutaa(formData, CORAAPIResponse);
          break;
        case "texas_f":
        case "texas_fb":
          dataGenerated = generateDataForFormTexas(formData, CORAAPIResponse);
          break;
        case "st_12ec":
          dataGenerated = generateDataForFormSt12(formData, CORAAPIResponse);
          break;
        case "mtc":
          dataGenerated = generateDataForFormMultipleStates(
            formData,
            CORAAPIResponse
          );
          break;
        case "st_10":
          dataGenerated = generateDataForFormSt10(formData, CORAAPIResponse);
          break;
        case "st_120":
          dataGenerated = generateDataForFormSt120(formData, CORAAPIResponse);
          break;
        case "st_4":
          dataGenerated = generateDataForFormSt4(formData, CORAAPIResponse);
          break;
        default:
          dataGenerated = "Unknown ID"; // Handle any unmatched IDs
          break;
      }

      // Send the processed dataGenerated back to Certificate
      dataToReturn(dataGenerated);
    }
  }, [signID, selectedId, formData, CORAAPIResponse]);

  return null;
};

export default DataGenerator;
