import {
  PDFDocument,
  StandardFonts,
  PDFTextField,
  PDFCheckBox,
  PDFDropdown,
} from "pdf-lib";
import React, { useEffect, useState } from "react";
import DataGenerator from "./DataGenerator.js";
import { useSelector, useDispatch } from "react-redux";
import { setHomePageData } from "../Home/redux/slice.js";
import SignatureVerification from "../SignatureVerification/index.js";

const Certificate = () => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [updatedPdfBytes, setUpdatedPdfBytes] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [showSignaturePopUp, setShowSignaturePopUp] = useState(false);
  const [signDataFromPopup, setSignDataFromPopup] = useState("");
  const [requiredDataRecived, setRequiredDataRecived] = useState({});
  const [isDataReady, setIsDataReady] = useState(false);
  const formData = useSelector((state) => state.Filloutform);
  const CORAAPIResponse = useSelector((state) => state.Home);
  const dispatch = useDispatch();
  const pdfMapping = {
    ssuta: "/SSUTAA.pdf", //1st
    texas_f: "/texas.pdf", //2nd
    texas_fb: "/texas.pdf", //2nd
    st_12ec: "/st12.pdf", //3rd
    mtc: "/MultipleStates.pdf", //4th
    st_10: "/st10.pdf", //5th
    st_120: "/st120.pdf", //6th
    st_4: "/st4.pdf", //7th
  };
  useEffect(() => {
    setSelectedId(CORAAPIResponse.template_name);
    if (!signDataFromPopup) setShowSignaturePopUp(true);
  }, []);
  useEffect(() => {
    if (isDataReady) {
      fillForm();
    }
  }, [isDataReady, signDataFromPopup]);

  const fillForm = async () => {
    try {
      const formUrl = pdfMapping[selectedId.toLowerCase()];

      if (!formUrl) {
        alert("Invalid ID");
        return;
      }

      const formPdfBytes = await fetch(formUrl).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(formPdfBytes);
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      if (
        selectedId.toLowerCase() === "texas_f" ||
        selectedId.toLowerCase() === "st_120"
      )
        pdfDoc.removePage(1);
      if (selectedId.toLowerCase() === "mtc") {
        const totalPages = pdfDoc.getPages().length;
        for (let i = totalPages - 1; i >= 1; i--) {
          pdfDoc.removePage(i);
        }
      }
      const requiredData = requiredDataRecived;
      const specialFont = await pdfDoc.embedFont(
        StandardFonts.TimesRomanBoldItalic
      );
      let emblemImage;
      if (signDataFromPopup) {
        const emblemImageBytes = await fetch(signDataFromPopup).then((res) =>
          res.arrayBuffer()
        );
        emblemImage = await pdfDoc.embedPng(emblemImageBytes);
      }

      // Fill the form fields dynamically
      fields.forEach((field) => {
        const fieldName = field.getName();
        if (requiredData[fieldName] !== undefined) {
          if (field instanceof PDFTextField) {
            if (
              fieldName === "Signature Purchaser" ||
              fieldName === "Signature Purchaser2" ||
              fieldName === "Signature" ||
              fieldName === "purchaser_sign"
            ) {
              if (emblemImage) {
                field.setImage(emblemImage);
              }
            } else {
              field.setText(requiredData[fieldName]);
            }
          } else if (field instanceof PDFCheckBox) {
            if (
              fieldName === "Blankate Certificate" ||
              (fieldName === "blanket_certificate" &&
                requiredData[fieldName] === true)
            ) {
              field.check();
            } else if (
              fieldName === "Retailer" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "Wholesaler" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "mtc_other" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "Resale_checkbox_autochecked" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "New_york_chekced" ||
              fieldName === "tangable_property_st120_checked" ||
              (fieldName === "type_food_st120_checked" &&
                requiredData[fieldName] === true)
            ) {
              field.check();
            } else if (fieldName === "Check Box1") {
              field.check();
            } else if (
              fieldName === "retailer_10_ssuta" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "wholesaler_13_ssuta" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "other_20_ssuta" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "resale_g_susta" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else if (
              fieldName === "other_m_susta" &&
              requiredData[fieldName] === true
            ) {
              field.check();
            } else {
              field.uncheck();
            }
            field.updateAppearances();
          } else if (field instanceof PDFDropdown) {
            field.select(requiredData[fieldName]);
          }
        }
      });
      // Flatten the form fields to make them non-editable
      form.flatten();

      // Save the modified PDF
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setUpdatedPdfBytes(pdfBytes);

      // Update iframe src to display the PDF
      const iframe = document.querySelector("iframe");
      if (iframe) iframe.src = url;
    } catch (error) {
      console.error("Error processing PDF:", error);
    }
  };

  useEffect(() => {
    if (updatedPdfBytes) {
      // Dispatch the blob to Redux
      const blob = new Blob([updatedPdfBytes], { type: "application/pdf" });
      dispatch(setHomePageData({ blob_data: blob }));
    }
  }, [updatedPdfBytes, dispatch]);

  // Callback to handle data from DataGenerator
  const handleData = (generatedData) => {
    setRequiredDataRecived(generatedData);
    setIsDataReady(true);
  };

  const getSignData = (data) => {
    setSignDataFromPopup(data);
  };
  return (
    <div>
      {showSignaturePopUp && (
        <div className="signature-popup">
          <SignatureVerification
            setFormName={formData?.name}
            setFormTitle={formData?.title}
            setSign={getSignData}
            closePopup={() => setShowSignaturePopUp(false)}
          />
        </div>
      )}
      {selectedId && (
        <DataGenerator
          selectedId={selectedId}
          dataToReturn={handleData}
          formData={formData}
          CORAAPIResponse={CORAAPIResponse}
        />
      )}
      {pdfUrl && Object.keys(requiredDataRecived).length > 0 && (
        <div style={{ position: "relative", width: "100%", height: "80vh" }}>
          <iframe
            allowtransparency="true"
            src={`${pdfUrl}#toolbar=0&zoom=120&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: "#FFFFFF",
            }}
            title="PDF Viewer"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Certificate;
