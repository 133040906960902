import moment from "moment";

export const formValidation = (values, valueFromAPI) => {
  const errors = {};

  // Helper function to check for valid phone number
  const isValidPhone = (phone) => /^[\s()+-]*(\d[\s()+-]*){6,14}$/.test(phone);

  // Helper function to check for valid email
  const isValidEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

  // Basic Required Field Check
  const requiredFields = [
    { name: "legalName", label: "Buyer Legal Entity Name is Required" },
    { name: "streetAdress", label: "Street Address is Required" },
    { name: "city", label: "City is Required" },
    { name: "state", label: "State is Required" },
    { name: "zip", label: "Zip is Required" },
    { name: "description", label: "Description is Required" },
    { name: "discriptionofitems", label: "Description of Items is Required" },
    { name: "stateTaxId", label: "State Tax ID is Required" },
    { name: "email", label: "Email is Required" },
    { name: "phonenumber", label: "Phone Number is Required" },
    { name: "date", label: "Date is Required" },
    { name: "name", label: "Name is Required" },
    { name: "title", label: "Title is Required" },
  ];

  requiredFields.forEach(({ name, label }) => {
    if (!values[name]) {
      errors[name] = label;
    }
  });

  // Specific Validations
  if (values.zip && !/^\d{5}$/.test(values.zip)) {
    errors.zip = "Buyer Zip must be a 5-digit number";
  }

  if (values.email && !isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  }
  if (values.phonenumber && !isValidPhone(values.phonenumber)) {
    errors.phonenumber = "Invalid Phone number";
  }

  if (
    valueFromAPI?.state?.toLowerCase() === "texas" &&
    values.stateTaxId &&
    !/^\d{11}$/.test(values.stateTaxId)
  ) {
    errors.stateTaxId = "Please enter your 11 digit state tax ID number";
  } else if (values.stateTaxId && !/^\d{1,11}$/.test(values.stateTaxId)) {
    errors.stateTaxId =
      "State Tax ID must be numeric and contain up to 11 digits";
  }

  if (values.date && !moment(values.date, "MM/DD/YYYY", true).isValid()) {
    errors.date = "Invalid date Format. Please use MM/DD/YYYY";
  }

  return errors;
};
